import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _315e5dce = () => interopDefault(import('../pages/account-requests/index.vue' /* webpackChunkName: "pages/account-requests/index" */))
const _7e9aa14c = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _42888d20 = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _5b46f9b5 = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _44726fa4 = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _d3027356 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _18c47192 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _04aeb1bf = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _4b6cd380 = () => interopDefault(import('../pages/financial-metrics/index.vue' /* webpackChunkName: "pages/financial-metrics/index" */))
const _31230336 = () => interopDefault(import('../pages/ht-alerts.vue' /* webpackChunkName: "pages/ht-alerts" */))
const _94988fca = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _5bc890d8 = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _5d42394f = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _d1cecab2 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f45ebd00 = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _6f01236e = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _19db2c92 = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _10b37efe = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _8af978c0 = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _6bb00ac2 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _0fe8b8c8 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _406fe2a6 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _1d555ce4 = () => interopDefault(import('../pages/operator-details/index.vue' /* webpackChunkName: "pages/operator-details/index" */))
const _29049250 = () => interopDefault(import('../pages/operator-traffic-dashboard/index.vue' /* webpackChunkName: "pages/operator-traffic-dashboard/index" */))
const _5f09a6c1 = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _57c6467a = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _2e896367 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _673fcc1f = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _646fe415 = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _4ad0add0 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _7f1691a0 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _187fcf28 = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _2dc9d860 = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _b6a79ea0 = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _b8911ee0 = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _3dd0707f = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _c933c650 = () => interopDefault(import('../pages/dashboard/productivity-metrics/index.vue' /* webpackChunkName: "pages/dashboard/productivity-metrics/index" */))
const _651a0388 = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _0ba6542e = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _115037a4 = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _3849ab58 = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _2e8c9b86 = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _4c29b271 = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _af23fb02 = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _708cadb8 = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _83538762 = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _1917bca3 = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _5b9ccff7 = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _5b9f2d75 = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _b920c856 = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _5a9f493f = () => interopDefault(import('../pages/profiles/request-update/request-update-mixin.js' /* webpackChunkName: "pages/profiles/request-update/request-update-mixin" */))
const _b213a082 = () => interopDefault(import('../pages/profiles/request-update/_profile.vue' /* webpackChunkName: "pages/profiles/request-update/_profile" */))
const _f7257a4e = () => interopDefault(import('../pages/account-requests/_request.vue' /* webpackChunkName: "pages/account-requests/_request" */))
const _6c80f6f5 = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _5797a4b9 = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _28b8995a = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _34badfd8 = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _0af4e620 = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _e151b0ec = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _78fa62ea = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _057b3999 = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _685b5b92 = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _2b88739a = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _be081a5e = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _10aa47a2 = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _0f0df8d3 = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _651e10e0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-requests",
    component: _315e5dce,
    name: "account-requests"
  }, {
    path: "/ai-reports",
    component: _7e9aa14c,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _42888d20,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _5b46f9b5,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _44726fa4,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _d3027356,
    name: "dashboard"
  }, {
    path: "/faqs",
    component: _18c47192,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _04aeb1bf,
    name: "feedback-funnel"
  }, {
    path: "/financial-metrics",
    component: _4b6cd380,
    name: "financial-metrics"
  }, {
    path: "/ht-alerts",
    component: _31230336,
    name: "ht-alerts"
  }, {
    path: "/invoices",
    component: _94988fca,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _5bc890d8,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _5d42394f,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _d1cecab2,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _f45ebd00,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _6f01236e,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _19db2c92,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _10b37efe,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _8af978c0,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _6bb00ac2,
    name: "my-team"
  }, {
    path: "/news",
    component: _0fe8b8c8,
    name: "news"
  }, {
    path: "/notifications",
    component: _406fe2a6,
    name: "notifications"
  }, {
    path: "/operator-details",
    component: _1d555ce4,
    name: "operator-details"
  }, {
    path: "/operator-traffic-dashboard",
    component: _29049250,
    name: "operator-traffic-dashboard"
  }, {
    path: "/payment-updates",
    component: _5f09a6c1,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _57c6467a,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _2e896367,
    name: "profile"
  }, {
    path: "/profiles",
    component: _673fcc1f,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _646fe415,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _4ad0add0,
    name: "settings"
  }, {
    path: "/stats",
    component: _7f1691a0,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _187fcf28,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _2dc9d860,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _b6a79ea0,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _b8911ee0,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _3dd0707f,
    name: "wink-replies"
  }, {
    path: "/dashboard/productivity-metrics",
    component: _c933c650,
    name: "dashboard-productivity-metrics"
  }, {
    path: "/manuals/new",
    component: _651a0388,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _0ba6542e,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _115037a4,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _3849ab58,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _2e8c9b86,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _4c29b271,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _af23fb02,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _708cadb8,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _83538762,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _1917bca3,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _5b9ccff7,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _5b9f2d75,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _b920c856,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/profiles/request-update/request-update-mixin",
    component: _5a9f493f,
    name: "profiles-request-update-request-update-mixin"
  }, {
    path: "/profiles/request-update/:profile?",
    component: _b213a082,
    name: "profiles-request-update-profile"
  }, {
    path: "/account-requests/:request?",
    component: _f7257a4e,
    name: "account-requests-request"
  }, {
    path: "/activity-logs/:type?",
    component: _6c80f6f5,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _5797a4b9,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _28b8995a,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _34badfd8,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _0af4e620,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _e151b0ec,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _78fa62ea,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _057b3999,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _685b5b92,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _2b88739a,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _be081a5e,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _10aa47a2,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _0f0df8d3,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _651e10e0,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
